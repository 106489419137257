import {
  defineStyleConfig,
  extendTheme,
} from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme';

const theme = {
  fonts: {
    ...proTheme.fonts,
    heading: '"Sofia Sans Condensed", sans-serif',
    body: '"Libre Franklin", sans-serif',
  },
  colors: {
    blue: {
      50: '#EBF3F9',
      100: '#C8DDEE',
      200: '#A5C7E4',
      300: '#82B1D9',
      400: '#5F9ACE',
      500: '#3C84C3',
      600: '#306A9C',
      700: '#244F75',
      800: '#18354E',
      900: '#0C1A27',
    },
    red: {
      50: '#FBEAEA',
      100: '#F2C4C4',
      200: '#EA9E9E',
      300: '#E27878',
      400: '#DA5353',
      500: '#D22D2D',
      600: '#A82424',
      700: '#7E1B1B',
      800: '#541212',
      900: '#2A0909',
    },
    gray: {
      50: '#F0F2F5',
      100: '#D5DAE2',
      200: '#BAC2CF',
      300: '#9EAABC',
      400: '#8392AA',
      500: '#687A97',
      600: '#536179',
      700: '#3E495B',
      800: '#2A313C',
      900: '#15181E',
    },
    green: {
      50: '#EBF9F2',
      100: '#C7EFDA',
      200: '#A4E5C2',
      300: '#80DBAA',
      400: '#5CD192',
      500: '#38C77A',
      600: '#2D9F61',
      700: '#227749',
      800: '#174F31',
      900: '#0B2818',
    },
    teal: {
      50: '#ECF7F9',
      100: '#C9E8EE',
      200: '#A6D9E2',
      300: '#84CAD7',
      400: '#61BBCC',
      500: '#3EACC1',
      600: '#328A9A',
      700: '#256774',
      800: '#19454D',
      900: '#0C2227',
    },
    purple: {
      50: '#EEEBFA',
      100: '#D0C6F1',
      200: '#B1A2E7',
      300: '#937DDE',
      400: '#7558D5',
      500: '#5634CB',
      600: '#4529A3',
      700: '#341F7A',
      800: '#221551',
      900: '#110A29',
    },
    pink: {
      50: '#FBE9F3',
      100: '#F5C2DD',
      200: '#EE9AC7',
      300: '#E873B1',
      400: '#E14C9A',
      500: '#DB2484',
      600: '#AF1D6A',
      700: '#83164F',
      800: '#580E35',
      900: '#2C071A',
    },
  },
  components: {
    Link: defineStyleConfig({
      baseStyle: {
        color: 'blue.600',
        textDecoration: 'underline',
      },
    }),
    Text: defineStyleConfig({
      baseStyle: {
        mb: 3,
      },
    }),
    Heading: defineStyleConfig({
      baseStyle: {
        mb: 3,
      },
    }),
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
    },
  },
};

theme.colors.brand = { ...theme.colors.teal };

export default extendTheme(
  proTheme,
  theme,
);

export const baseTheme = proTheme;
